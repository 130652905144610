import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import "lib-flexible";
import "@/style/reset.css";
import 'vant/lib/index.css';
import { Loading, Toast, Dialog } from 'vant';
// import VConsole from 'vconsole';

console.log("当前环境", process.env.VUE_APP_ENV)


// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const vConsole = process.env.VUE_APP_ENV !== 'production' ? new VConsole({ theme: 'dark' }) : null;


createApp(App).use(store).use(router).use(Loading).use(Toast).use(Dialog).mount('#app')