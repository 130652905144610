<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view/>
</template>

<style>
#app {
  font-family: '微软雅黑';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  font-size: 13px;
}
body:before {
  width: 100%;
  height: 100%;
  content: ' ';
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: #000;
}
</style>
