import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Policy",
    component: () => import("@/views/app/PolicyCH.vue"),
  },
  {
    path: "/app/policy-ch",
    name: "PolicyCH",
    component: () => import("@/views/app/PolicyCH.vue"),
  },
  {
    path: "/app/policy-en",
    name: "PolicyEN",
    component: () => import("@/views/app/PolicyEN.vue"),
  },
  {
    path: "/app/android/policy-ch",
    name: "AndroidPolicyCH",
    component: () => import("@/views/app/android/PolicyCH.vue"),
  },
  {
    path: "/app/android/policy-en",
    name: "AndroidPolicyEN",
    component: () => import("@/views/app/android/PolicyEN.vue"),
  },
  {
    path: "/app/android/user-agree-ch",
    name: "AndroidUserAgreeCH",
    component: () => import("@/views/app/android/UserAgreeCH.vue"),
  },
  {
    path: "/app/android/user-agree-en",
    name: "AndroidUserUserAgreeEN",
    component: () => import("@/views/app/android/UserAgreeEN.vue"),
  },
  // 海外
  {
    path: "/app/android-foreign/policy-ch",
    name: "AndroidForeignPolicyCH",
    component: () => import("@/views/app/androidForeign/PolicyCH.vue"),
  },
  {
    path: "/app/android-foreign/policy-en",
    name: "AndroidForeignPolicyEN",
    component: () => import("@/views/app/androidForeign/PolicyEN.vue"),
  },
  {
    path: "/app/android-foreign/user-agree-ch",
    name: "AndroidForeignUserAgreeCH",
    component: () => import("@/views/app/androidForeign/UserAgreeCH.vue"),
  },
  {
    path: "/app/android-foreign/user-agree-en",
    name: "AndroidForeignUserUserAgreeEN",
    component: () => import("@/views/app/androidForeign/UserAgreeEN.vue"),
  },
  // 
  {
    path: "/app/xiaomi/policy-ch",
    name: "xiaomiPolicyCH",
    component: () => import("@/views/app/xiaomi/PolicyCH.vue"),
  },
  {
    path: "/app/xiaomi/policy-en",
    name: "xiaomiPolicyEN",
    component: () => import("@/views/app/xiaomi/PolicyEN.vue"),
  },
  {
    path: "/app/xiaomi/user-agree-ch",
    name: "xiaomiUserAgreeCH",
    component: () => import("@/views/app/xiaomi/UserAgreeCH.vue"),
  },
  {
    path: "/app/xiaomi/user-agree-en",
    name: "xiaomiUserUserAgreeEN",
    component: () => import("@/views/app/xiaomi/UserAgreeEN.vue"),
  },
  {
    path: "/ios/policy-ch",
    name: "IosPolicyCH",
    component: () => import("@/views/app/ios/PolicyCH.vue"),
  },
  {
    path: "/ios/policy-en",
    name: "IosPolicyEN",
    component: () => import("@/views/app/ios/PolicyEN.vue"),
  },
  {
    path: "/ios/user-agree-ch",
    name: "IosUserAgreeCH",
    component: () => import("@/views/app/ios/UserAgreeCH.vue"),
  },
  {
    path: "/ios/user-agree-en",
    name: "IosUserUserAgreeEN",
    component: () => import("@/views/app/ios/UserAgreeEN.vue"),
  },

  {
    path: "/ios/instant/policy-ch",
    name: "IosInstantPolicyCH",
    component: () => import("@/views/app/ios/instant/PolicyCH.vue"),
  },
  {
    path: "/ios/instant/policy-en",
    name: "IosInstantPolicyEN",
    component: () => import("@/views/app/ios/instant/PolicyEN.vue"),
  },
  {
    path: "/ios/instant/user-agree-ch",
    name: "IosInstantUserAgreeCH",
    component: () => import("@/views/app/ios/instant/UserAgreeCH.vue"),
  },
  {
    path: "/ios/instant/user-agree-en",
    name: "IosInstantUserUserAgreeEN",
    component: () => import("@/views/app/ios/instant/UserAgreeEN.vue"),
  },

  {
    path: "/ios/instant-reset/policy-ch",
    name: "IosInstantResetPolicyCH",
    component: () => import("@/views/app/ios/instant-reset/PolicyCH.vue"),
  },
  {
    path: "/ios/instant-reset/policy-en",
    name: "IosInstantResetPolicyEN",
    component: () => import("@/views/app/ios/instant-reset/PolicyEN.vue"),
  },
  {
    path: "/ios/instant-reset/user-agree-ch",
    name: "IosInstantResetUserAgreeCH",
    component: () => import("@/views/app/ios/instant-reset/UserAgreeCH.vue"),
  },
  {
    path: "/ios/instant-reset/user-agree-en",
    name: "IosInstantResetUserUserAgreeEN",
    component: () => import("@/views/app/ios/instant-reset/UserAgreeEN.vue"),
  },

  {
    path: "/sdk/sdk-ch",
    name: "SdkCH",
    component: () => import("@/views/app/SdkCH.vue"),
  },
  {
    path: "/sdk/sdk-en",
    name: "SdkEN",
    component: () => import("@/views/app/SdkEN.vue"),
  },
  {
    path: "/webgls",
    name: "webgls",
    component: () => import("@/views/webgl/index.vue"),
  },
  {
    path: "/video",
    name: "video",
    component: () => import("@/views/video/index.vue"),
  },
  {
    path: "/video/tot",
    name: "tot",
    component: () => import("@/views/video/video.vue"),
  },
  {
    path: "/video/qingci",
    name: "qingci",
    component: () => import("@/views/video/qingci.vue"),
  },
  {
    path: "/video/toypc",
    name: "toypc",
    component: () => import("@/views/video/toypc.vue"),
  },
  {
    path: "/video/backend",
    name: "backend",
    component: () => import("@/views/video/backend.vue"),
  },
  {
    path: "/video/totm",
    name: "totm",
    component: () => import("@/views/video/tot-m.vue"),
  },
  {
    path: "/video/toypcnew",
    name: "toypcnew",
    component: () => import("@/views/video/toy-pc.vue"),
  },
  {
    path: "/video/miniapp",
    name: "miniapp",
    component: () => import("@/views/video/miniapp.vue"),
  },
  {
    path: "/video/sharepeople",
    name: "sharepeople",
    component: () => import("@/views/video/sharepeople.vue"),
  },
  // 最新通用
  {
    path: "/app/common/policy-ch",
    name: "PolicyCHCommon",
    component: () => import("@/views/app/common/PolicyCH.vue"),
  },
  {
    path: "/app/common/user-agree-ch",
    name: "UserAgreeCHCommon",
    component: () => import("@/views/app/common/UserAgreeCH.vue"),
  },

  {
    path: "/test/index",
    name: "testIndex",
    component: () => import("@/views/test/Index.vue"),
  },

  {
    path: "/yijifen/active",
    name: "yijifenActive",
    component: () => import("@/views/yijifen/Active.vue"),
  },
  {
    path: "/yijifen/login",
    name: "yijifenLogin",
    component: () => import("@/views/yijifen/login.vue"),
  },
  {
    path: "/yijifen/verification",
    name: "yijifenVerification",
    component: () => import("@/views/yijifen/verification.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 配置白名单
// const whiteList = ["Policy"];

// router.beforeEach((to, from, next) => {
//   // if (whiteList.includes(to.name)) {
//   //   next();
//   // } else {
//   //   next('/')
//   // }
// });

export default router;
